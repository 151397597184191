<template>
	<div class="row">
		<div class="col-12 col-xl-12">
			<wallet-transactions-history-table :user-id="userId">
			</wallet-transactions-history-table>
		</div>
	</div>
</template>

<script>
import WalletTransactionsHistoryTable from '@/components/modules/transactions/WalletTansactionsHistoryTable'
export default {
  name: 'UserTransactions',
  components: { WalletTransactionsHistoryTable },
  props: {
    userId: {
      required: true,
      type: [Number, String]
    }
  }
}
</script>

<style scoped></style>
