<template>
	<div class="card">
		<div class="card-header">
			<h4 class="card-header-title" style="flex: 0">Transactions</h4>
			<div class="d-flex justify-content-between">
				<div class="input-group input-group-flush d-flex flex-row-reverse">
					<input
						v-model.trim="search"
						class="form-control list-search"
						type="search"
						placeholder="Search"
						/>
					<span class="input-group-text border-0">
						<i class="fe fe-search"></i>
					</span>
				</div>
			</div>
		</div>
		<div class="card-body p-0">
			<b-table
				striped
				hover
				show-empty
				selectable
				:items="filteredHistory"
				:fields="fields"
				:per-page="perPage"
				:current-page="currentPage"
				:busy="loadingWalletHistory"
				@row-clicked="viewWalletTransaction"
				>
				<template #table-busy>
					<div class="d-flex flex-column justify-content-center text-secondary my-2 align-items-center">
						<div class="spinner-border" role="status"></div>
						<p class="text-center mt-2"><strong>Loading...</strong></p>
					</div>
				</template>
				<template #empty>
					<h4 class="text-center">No transaction records available.</h4>
				</template>
				<template #cell(created_at)="data">
					{{ data.value | date('hh:mm a MMM d, yyyy') }}
				</template>
				<template #cell(amount)="data">
					<span
						:class="data.item.type === 'debit' ? 'text-danger' : 'text-success'"
						>₦{{ data.value | money }}</span
						>
				</template>
				<template #cell(payment_source)="data">
					<span
						>{{ data.item.payment_source }}</span>
            <br>
            <span v-if="data.item.payment_gateway ">({{ data.item.payment_gateway }})</span
						>
				</template>
			</b-table>

			<div class="card-footer" v-if="filteredHistory.length">
				<b-pagination
					v-model="currentPage"
					:total-rows="filteredHistory.length"
					:per-page="perPage"
					></b-pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: ['userId'],
  data () {
    return {
      loadingWalletHistory: true,
      errorLoadingWalletHistory: false,
      history: [],
      search: '',
      fields: [
        {
          key: 'created_at',
          label: 'Transaction Date'
        },
        {
          key: 'title',
          sortable: true,
          label: 'Description'
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true
        },
        {
          key: 'payment_source',
          label: 'Source',
          sortable: false
        }
      ],
      perPage: 10,
      currentPage: 1
    }
  },
  created () {
    this.fetchWalletHistory()
  },
  computed: {
    filteredHistory () {
      const search = this.search.toLowerCase()

      return this.history.filter((user) => {
        return (
          user.title.toLowerCase().includes(search) ||
          user.amount.toLowerCase().includes(search) ||
          user.payment_source.toLowerCase().includes(search)
        )
      })
    }
  },
  methods: {
    fetchWalletHistory () {
      this.loadingWalletHistory = true
      this.errorLoadingWalletHistory = false

      this.axios
        .get(`/v1/users/${this.userId}/wallet?limit=1000`)
        .then((res) => {
          this.history = res.data.walletHistory
        })
        .catch(() => (this.errorLoadingWalletHistory = true))
        .finally(() => (this.loadingWalletHistory = false))
    },

    viewWalletTransaction (transaction) {
      this.$router.push({
        name: 'transactions.view',
        params: { transactionId: transaction.id }
      })
    }
  }
}
</script>
